<template>
  <b-row
    v-if="ready"
  >
    <b-col lg="12">
      <b-row>
        <b-col lg="6">
          <b-form-select v-model="afficherDoneOuPas" :options="optionsDone"></b-form-select>
        </b-col>
        <b-col>
          <b-button class="mb-2" v-b-toggle.sidebar-right>Ajouter une tâche</b-button>
        </b-col>
      </b-row>

      <b-table ref="table" small striped hover :fields="fields" :items="taches" :tbody-tr-class="rowClass">
        <template #cell(date)="data">
          {{ (new Date(data.value*1000)).getDate() }}/{{ (new Date(data.value*1000)).getMonth() + 1 }}/{{ (new Date(data.value*1000)).getFullYear() }} à {{ (new Date(data.value*1000)).getHours() }}:{{ (new Date(data.value*1000)).getMinutes() }}
        </template>
        <template #cell(priorite)="data">
          <span v-if="data.value == 4">
            <b-badge variant="danger">URGENT</b-badge>
          </span>
          <span v-else-if="data.value == 3">
            <b-badge variant="warning">ASAP</b-badge>
          </span>
          <span v-else-if="data.value == 2">
            <b-badge variant="info">Moyen</b-badge>
          </span>
          <span v-else-if="data.value == 1">
            <b-badge variant="primary">Quand tu peux</b-badge>
          </span>
        </template>
        <template #cell(id)="data">
          <b-form-checkbox @change="someHandler(data.value)" v-model="data.item.status" name="check-button" switch>
          </b-form-checkbox>
        </template>
        <template #cell(show_details)="row">
          <b-button size="sm" @click="row.toggleDetails" class="mr-2">
            {{ row.detailsShowing ? 'Cacher' : 'Voir'}}
          </b-button>
        </template>
        <template #row-details="row">
          <b-card>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Description de la tâche :</b></b-col>
              <b-col>{{ row.item.description }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col>
                <div style="width:100%;height:200px;border:1px solid grey;border-radius:10px;" class="p-1">
                  <div style='width:100%; height:80%;overflow:scroll;'>
                    <div v-for="commentaire in row.item.commentaires">
                      <strong>{{ commentaire.user }} le {{ (new Date(commentaire.date*1000)).getDate() }}/{{ (new Date(commentaire.date*1000)).getMonth() + 1 }}/{{ (new Date(commentaire.date*1000)).getFullYear() }} à {{ (new Date(commentaire.date*1000)).getHours() }}:{{ (new Date(commentaire.date*1000)).getMinutes() }}</strong> : {{ commentaire.commentaire }}
                    </div>
                  </div>
                  <div style="width:100%; height:20%;">
                    <b-form-input v-on:keyup.enter="onEnter(row.index)" v-model="envoiecommentaire" placeholder="Entre ton commentaire"></b-form-input>
                  </diV>
                </div>
              </b-col>
            </b-row>

            <b-button size="sm" @click="row.toggleDetails">Cacher les détails</b-button>
          </b-card>
        </template>
      </b-table>
    </b-col>
    <b-sidebar id="sidebar-right" title="Allez, on crée une tâche" right shadow>
      <div class="px-3 py-2">
        <p>Nom de la tâche</p>
        <b-form-input v-model="nom" placeholder="Entrez un nom pour la tâche"></b-form-input>
        <p class="mt-2">Description</p>
        <b-form-textarea
          v-model="description"
          placeholder="Description de la tâche"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
        <p class="mt-2">Priorité</p>
        <b-form-rating variant="warning" stars="4" show-value show-value-max v-model="priorite"></b-form-rating>
        <p class="mt-2">Client concerné</p>
        <b-form-select v-model="clientConcerne" :options="optionsClient"></b-form-select>
        <p class="mt-2">Qui doit faire la tâche ?</p>
        <b-form-select v-model="adminConcerne" :options="optionsAdmin"></b-form-select>

        <b-button @click="creer" class="mt-2">Créer</b-button>



      </div>
    </b-sidebar>
  </b-row>
</template>

<script>
import router from '@/router'
import axios from '@axios'
import {
  BFormCheckbox, BBadge, BFormSelect, BFormRating, BFormTextarea, BTable,BCard, BCardText, BRow, BCol, BButton, BImg, BSidebar, VBToggle, BCarousel, BCarouselSlide, BEmbed, BAvatar, BAvatarGroup, BFormGroup, BFormInput, BFormFile,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import Ripple from 'vue-ripple-directive'

import VideoConverter from 'convert-video'

export default {
  components: {
    BFormCheckbox,
    BBadge,
    BFormSelect,
    BFormRating,
    BFormTextarea,
    BTable,
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BImg,
    BSidebar,
    /* eslint-disable vue/no-unused-components */
    VBToggle,
    BCarousel,
    BCarouselSlide,
    BEmbed,
    BAvatar,
    BAvatarGroup,
    BFormGroup,
    BFormInput,
    BFormFile,
    ToastificationContent,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      envoiecommentaire: '',
      fields: [
        {
          key: 'user',
          label: 'Demandé par',
          sortable: true
        },
        {
          key: 'nom',
          sortable: true
        },
        {
          key: 'priorite',
          sortable: true
        },
        {
          key: 'adminConcerne',
          label: 'Admin Concerné',
          sortable: true,
        },
        {
          key: 'clientConcerne',
          label: 'Pour ce client',
          sortable: true,
        },
        {
          key: 'date',
          label: 'Demandé le',
          sortable: true,
        },
        {
          key: 'id',
          label: 'Done ?',
        },
        {
          key: 'show_details',
          label: 'En savoir plus'
        },
      ],
      description: '',
      optionsDone: [{text: 'Effectuées', value: 1}, {text: 'Non effectuées', value: 0}],
      nom: '',
      afficherDoneOuPas: 0,
      priorite: 1,
      adminConcerne: '',
      clientConcerne: '',
      status: '',
      taches: [],
      ready: false,
      file: null,
    }
  },
  methods: {
    onEnter(id) {
      var data = {
        'text': this.envoiecommentaire,
        'id_tache': this.$refs.table.items[id]['id']
      }
      axios
        .post('https://app2.oscar-black.com/api/tache/commentaire/'+this.afficherDoneOuPas, data)
        .then(response => {
          this.taches = response.data.taches
          this.optionsClient = response.data.client
          this.optionsAdmin = response.data.admin
          this.envoiecommentaire = ''
          setTimeout(() => { this.ready = true }, 500)
        })
        .catch(() => {})
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.status === true) return 'table-success'
    },
    someHandler(id) {
      var data = {
        id: id,
      }
      axios
        .post('https://app2.oscar-black.com/api/tache/modifyStatus/'+this.afficherDoneOuPas, data)
        .then(response => {
          this.taches = response.data.taches
          this.optionsClient = response.data.client
          this.optionsAdmin = response.data.admin
          setTimeout(() => { this.ready = true }, 500)
        })
        .catch(() => {})
    },
    creer() {
      var data = {
        nom: this.nom,
        description: this.description,
        priorite: this.priorite,
        adminConcerne: this.adminConcerne,
        clientConcerne: this.clientConcerne
      }
      axios
        .post('https://app2.oscar-black.com/api/tache/'+this.afficherDoneOuPas, data)
        .then(response => {
          this.taches = response.data.taches
          this.optionsClient = response.data.client
          this.optionsAdmin = response.data.admin
          setTimeout(() => { this.ready = true }, 500)
        })
        .catch(() => {})
    },
    confirmDelete(id) {
      this.$swal({
        title: 'Êtes-vous sûr ?',
        text: 'Il sera impossible de revenir en arrière...',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui, supprimer ce fichier',
        cancelButtonText: 'STOP',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const req = {
            _id: id,
            iddossier: router.currentRoute.params.id,
          }
          axios
            .delete('https://app2.oscar-black.com/api/drive', { data: req })
            .then(response => {
              this.drives = response.data
              this.$swal({
                icon: 'success',
                title: 'Supprimé !',
                text: 'Votre fichier a bien été supprimé.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              this.$swal({
                icon: 'error',
                title: 'Oops, pas supprimé !',
                text: error.response.data,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Annulé',
            text: 'Votre fichier est en sécurité :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },

  },
  created() {
    axios
      .get('https://app2.oscar-black.com/api/tache/'+this.afficherDoneOuPas)
      .then(response => {
        this.taches = response.data.taches
        this.optionsClient = response.data.client
        this.optionsAdmin = response.data.admin
        setTimeout(() => { this.ready = true }, 500)
      })
      .catch(() => {})
  },
  watch: {
    afficherDoneOuPas(val) {
      console.log(val)
      axios
        .get('https://app2.oscar-black.com/api/tache/'+this.afficherDoneOuPas)
        .then(response => {
          this.taches = response.data.taches
          this.optionsClient = response.data.client
          this.optionsAdmin = response.data.admin
          setTimeout(() => { this.ready = true }, 500)
        })
        .catch(() => {})
    }

  }
}
</script>

<style lang="scss">
  .custom-file-input:lang(fr) ~ .custom-file-label::after {
    content: 'Parcourir';
  }
</style>
